<template>
<div id="EventWelcome" v-if="EventData != undefined || EventData != null">
<v-container>

<!-- event data -->
<div>

    <v-row align="center" justify="center">
     <v-col cols="12" sm="12" md="6" class="my-5">
        <v-img :src="EventData.imageUrl" height="400px" alt="Event Image" class="elevation-10 white animated fadeIn slow">
            <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular :size="80" :width="15" color="purple" indeterminate ></v-progress-circular>
                </v-row>
            </template>
        </v-img>
     </v-col>
    </v-row>

    <div class="mt-5">
        <p class="subtitle-1">Date : {{EventData.DOE}}</p>
        <p class="headline py-5 my-5">{{EventData.title}}</p>
    </div>

    <p class="my-5 subtitle-1" v-html="EventData.description"></p>

</div>

<!-- past events -->
<div class="mt-5 pt-5">

    <p class="title mb-2">More News</p><hr class="mb-5" width="250px">

    <v-row height="100%" class="mt-5" v-show="GetPastEvents">
    <v-col cols="12" sm="12" md="4" class="my-3" v-for="event in GetPastEvents" :key="event.eventslug" height="100%">
    <v-fade-transition>
        <v-card class="elevation-5 grey lighten-3 animated fadeIn slower" height="100%">

          <v-img height="200px" class="animated fadeIn slower" aspect-ratio="1" :src="event.imageUrl">
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
              <v-progress-circular :size="80" :width="15" color="purple" indeterminate ></v-progress-circular>
              </v-row>
            </template>
          </v-img>

          <div class="card-strip elevation-5">
            <v-card-subtitle class="py-3 subtitle"> Date : {{event.DOE}}</v-card-subtitle>
          </div>

          <v-card-text class="subtitle-1 card-outter">
            <p class="py-3">{{event.title}}</p>
          </v-card-text>

          <v-card-actions class="card-actions">
            <v-btn class="action-btn elevation-5" @click="$router.push('/eventoverview/' + event.eventslug)">
              View<v-icon class="ml-2" dark>mdi-chevron-double-right</v-icon>
            </v-btn>
          </v-card-actions>


        </v-card>
    </v-fade-transition>
    </v-col>
    </v-row>

    <div v-show="!GetPastEvents" class="my-5" height="100%">
      <v-row>
          <v-col cols="12" sm="12" md="4" v-for="n in 6" :key="n">
          <v-skeleton-loader class="mx-auto" type="card, list-item, actions">
          </v-skeleton-loader>
          </v-col>
      </v-row>
    </div>

</div>

</v-container>
</div>
</template>

<script>
export default {

    name: 'EventWelcome',

    computed:{

        EventSlug(){         
          return this.$route.params.eventslug != null ? this.$route.params.eventslug : null;
        },

        EventData(){
            if(this.EventSlug != null) {
                let data =  this.$store.getters.GetEventWithSlug(this.EventSlug);
                return data.length != 0 ? data[0] : null;
            }else{ return this.$router.push("/"); }             
        },

        GetPastEvents(){
            let data = this.$store.getters.GetPastEvents.filter(event => event.eventslug != this.EventSlug).slice(0, 6);
            return data != '' ? data : null;
        },

    }

}
</script>

<style scoped>
#EventWelcome{
    padding-bottom: 3rem;
}
.card-strip{
  background-color: #1A1043 !important;
}
.card-strip .subtitle{
  color: #ddd !important;
}
.card-outter {
  position: relative;
  padding-bottom: 60px;
  color: #222 !important;
}
.card-actions {
  position: absolute;
  bottom: 0;
  padding: 20px 13px
}
.card-actions .action-btn {
  background-color: #1A1043 !important;
  border: 1px solid #ddd !important;
  color: #ddd !important;
}
</style>